
























































































































































































































import _ from 'lodash';
import Vue from 'vue';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { DataTable, SurveyForm } from '@/components/admin';
import { Component, Mixins } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';
import SurveyFormMixin from '@/mixins/SurveyFormMixin';
import SurveyMixin from '@/mixins/SurveyMixin';

@Component({
  name: 'Dashboard',
  components: {
    DataTable,
    SurveyForm,
  },
  computed: {
    ...mapGetters('survey', ['getList', 'getCount']),
  },
})
export default class Dashboard extends Mixins(SurveyFormMixin, SurveyMixin) {
  selected: any = {};
  summary: any = {};
  fields = [
    {
      key: 'client.name',
      label: 'Client',
      sortable: true,
      thClass: 'width-140',
    },
    {
      key: 'projectCode',
      sortable: true,
      thClass: 'width-100',
      tdClass: 'text-uppercase',
    },
    {
      key: 'teamName',
      sortable: true,
      thClass: 'width-95',
    },
    {
      key: 'template.name',
      sortKey: 'name',
      sortable: true,
      label: 'Survey Type',
      thClass: 'survey-type',
    },
    {
      key: 'status',
      sortable: true,
      thClass: 'width-135',
    },
    {
      key: 'createdAt',
      label: 'Created',
      sortable: true,
      formatter(val) {
        return val ? moment(val).format(process.env.VUE_APP_DATE_FORMAT) : null;
      },
    },
    {
      key: 'startDate',
      sortable: true,
      thClass: 'width-85',
      formatter(val) {
        return val ? moment(val).format(process.env.VUE_APP_DATE_FORMAT) : null;
      },
    },
    {
      key: 'endDate',
      sortable: true,
      formatter(val) {
        return val ? moment(val).format(process.env.VUE_APP_DATE_FORMAT) : null;
      },
    },
    {
      key: 'participantLimit',
      label: 'Participants',
      sortable: true,
      thClass: 'width-100',
    },
    {
      key: 'totalParticipants',
      label: 'Completions',
      sortable: true,
      thClass: 'width-100',
    },
    {
      key: 'percentComplete',
      label: 'Complete',
      sortable: true,
      thClass: 'width-95',
      formatter(val) {
        return `${val}%`;
      },
    },
    {
      key: 'daysLeft',
      sortable: true,
      formatter(val) {
        return _.isInteger(val) ? val : '-';
      },
    },
    {
      key: 'averageTime',
      label: 'Avg Time',
      sortable: true,
      formatter(val) {
        const minutes = (val / 60)
          .toFixed(1)
          .replace('0.0', '')
          .replace('.0', '');
        return parseInt(minutes) ? `${minutes} mins` : '-';
        // return val ? moment.duration(val, 'seconds').humanize() : '-'
      },
    },
  ];

  completionFields = [
    {
      key: 'fullName',
      label: 'Name',
      sortable: true,
    },
    {
      key: 'createdAt',
      label: 'Submitted',
      formatter(val) {
        return val
          ? moment(val).format(process.env.VUE_APP_DATETIME_FORMAT)
          : null;
      },
      sortable: true,
    },
  ];

  fetchData(params: Record<string, string | boolean> = {}) {
    params.active = true;
    params.sort = params.sort || '-endDate';
    this.$store.dispatch('survey/list', { params });
    this.$store.dispatch('survey/count', { params });

    const params2 = {
      clients: this.$route.params.client,
    };
    this.$store
      .dispatch('survey/getSummary', { params: params2 })
      .then((res) => {
        this.summary = res;
        // try {
        // const averageTime = res.totalTime / res.totalParticipants
        // const minutes = (averageTime / 60).toFixed(1)
        // // this.summary.averageTime = moment.duration(averageTime, 'seconds').humanize()
        // this.summary.averageTime = `${minutes} minutes`.replace('.0', '')
        // } catch(err) {
        //     console.log(err)
        // }
        for (const surveyName in this.summary.surveyStats) {
          const averageTime =
            this.summary.surveyStats[surveyName].totalTime /
            this.summary.surveyStats[surveyName].totalParticipants;
          const minutes = averageTime
            ? (averageTime / 60).toFixed(1).replace('0.0', '').replace('.0', '')
            : null;
          // this.summary.averageTime = moment.duration(averageTime, 'seconds').humanize()
          this.summary.surveyStats[surveyName].averageTime =
            minutes && parseInt(minutes) ? `${minutes} minutes` : '-';
        }
      });
  }

  editSurvey(survey) {
    this.selected = Object.assign({}, survey);
    this.$refs.editModal.show();
  }

  onCancel() {
    this.selected = null;
    this.$refs.editModal.hide();
  }
}
